<template>
  <v-autocomplete
    v-bind:value="input.value"
    @change="
      input.value = $event;
      $emit('passChange', input);
    "
    :items="input.items"
    :label="input.name"
    :item-text="input.itemText"
    :return-object="input.returnObject"
    required
    outlined
    clearable
    :rules="rulesObj(input.rules)"
    open-on-clear
    dense
    color="#3d2cdd"
  >
  </v-autocomplete>
</template>
<script>
export default {
  name: "FormAutocomplete",
  props: ["input"],
  data() {
    return {};
  },
  methods: {
    rulesObj(input) {
      // If the rules object exists then return
      // Else don't return anything
      if (typeof input === "undefined") return;
      return Object.values(input);
    },
  },
};
</script>